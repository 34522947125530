// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.link
  white-space: nowrap

  &:not(.primary):not(.secondary)
    text-decoration: none

  &.primary
    color: $c-active-blue

    &:active,
    &:hover
      color: $c-active-blue-hover
      text-decoration: underline

    &.link-visited
      &:visited
        color: $c-active-blue-active

  &.secondary
    color: $tc-subtle-gray
    &:active,
    &:hover
      color: $tc-deep-gray

    &.link-visited
      &:visited
        color: $tc-deep-gray

  &.primary, &.secondary
    &.disabled
      opacity: .5
      cursor: not-allowed

.doc-link
  white-space: nowrap

.icon
  nudge('up', 2px)
  margin-left: $cs.xxs
  margin-right: $cs.xxs
  font-size: 0.9rem

.doc-icon
  nudge('up')
