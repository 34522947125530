// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.wrapper
  cursor: pointer
  display: flex
  flex-wrap: nowrap
  justify-content: flex-start
  align-items: center
  margin-top: $cs.xxs

  &:not(.disabled):hover .checkmark
    background-color: $c-backdrop

  &:not(.disabled):hover input:checked ~ .checkmark, input:checked:focus ~ .checkmark
    background-color: lighter($c-active-blue)
    border-color: lighter($c-active-blue)

.disabled
  cursor: not-allowed
  color: $tc-subtle-gray

  .checkbox
    opacity .4

.checkbox
  position: relative
  display: inline-block
  white-space: nowrap
  vertical-align: middle
  outline: 0

  input
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 1
    width: 100%
    height: 100%
    cursor: pointer
    opacity: 0

    &:disabled
      cursor: not-allowed

  input:checked ~ .checkmark
    border-color: $c-active-blue
    background-color: $c-active-blue

  input:checked ~ .checkmark:after
    opacity: 1
    transform: rotate(45deg) scale(1) translate(-50%, -50%)
    transition: all $ad.s cubic-bezier(.12, .4, .29, 1.40) $ad.xs

  input:focus ~ .checkmark
    border-color: $c-active-blue

.checkmark
  border-input()
  border-radius: $br.s
  position: relative
  top: 0
  left: 0
  display: block
  width: 1.08rem
  height: 1.08rem
  transition: all $ad.s

  &:after
    opacity: 0
    position: absolute
    left: 3px
    top: 7px
    width: 4px
    height: 9px
    border: solid white
    border-width: 0 2px 2px 0
    transform: rotate(45deg) scale(0) translate(-50%, -50%)
    transition: transform $ad.s ease-out $ad.xs
    content: ''

.indeterminate
  .checkmark:after
    border: 0
    top: 50%
    left: 50%
    width: 50%
    height: 50%
    background-color: $c-active-blue
    transform: translate(-50%, -50%) scale(1)
    opacity: 1
    transition: none
    content: ''

.label
  padding-left: $cs.xs
